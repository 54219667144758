<template>
  <div class='complete'>
    <layout-header />
    <main class='complete__main'>
      <section class='complete__main__modal'>
        <div class='complete__main__modal__text'>
          <h3>チケット使用完了</h3>
          <p>チケットの使用を完了しました。<br>このページを閉じチャットの返答をお待ちください。</p>
        </div>
      </section>
    </main>
    <layout-footer />
  </div>
</template>

<script>
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
  },
};
</script>

<style lang="scss" scoped>
.complete {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.complete__main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.complete__main__modal {
  background-color: $color-white;
  width: 500px;
  text-align: center;
  padding: 30px 30px 35px;
}

.complete__main__modal__text {
  h3 {
    font-size: 24px;
  }
  p {
    font-size: 14px;
    margin: 10px 0 20px;
  }
  a {
    text-decoration: none;
    color: $color-black;
  }
  a:visited {
    color: $color-black;
  }
}
</style>
